import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook13/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook13/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook13/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook13/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook13/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook13/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook13/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo automatizar un ecommerce con chatbots y WhatsApp
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    Descubre cómo los chatbots pueden brindar respuestas
    rápidas y personalizadas a las consultas de los clientes. Desde
    recomendaciones de productos hasta seguimiento de pedidos, los chatbots
    pueden automatizar numerosas tareas.
    <br /> <br />
    Además, integrar WhatsApp como canal de comunicación amplía la accesibilidad
    y comodidad para los clientes. Podrás enviar notificaciones de envío,
    confirmaciones de compra y responder consultas en tiempo real.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    La automatización para el comercio electrónico brinda numerosos beneficios.
    Al implementar esta estrategia, los negocios pueden optimizar la atención al
    cliente, agilizar los procesos de gestión de inventario y mejorar la
    eficiencia general en las operaciones.
    <br /> <br />
    Sin embargo, la automatización en el comercio electrónico no se trata solo
    de realizar tareas. En muchos casos, puede llevarlas a cabo de manera más
    eficiente, evitando la necesidad de corregir errores posteriores.
  </p>
)
const textTwo = (
  <p>
    En respuesta a la creciente demanda de los usuarios, las empresas se han
    dado cuenta de la importancia de mantener un flujo de servicios
    ininterrumpido las 24 horas del día para adaptarse a sus necesidades
    <br /> <br />
    Una de las soluciones más populares ha sido la implementación de chatbots
    para brindar atención al cliente autónoma.
  </p>
)
const textThree = (
  <p>
    WhatsApp ofrece ventajas únicas, como una alta tasa de apertura de mensajes
    del 98% y una tasa de clics del 45% al 60%.
  </p>
)

const data = {
  start: {
    support: "Ebook | Ecommerce ",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "¿Qué significa la automatización para un e-commerce?",
      },
      {
        key: 2,
        text: "¿Qué automatizar en un e-commerce?",
      },
      {
        key: 3,
        text: "¿Cómo automatizar un e-commerce con chatbots?",
      },
      {
        key: 4,
        text: "¿Cómo automatizar un e-commerce con WhatsApp?",
      },
      {
        key: 5,
        text: "Implementación de chatbots para brindar atención al cliente",
      },
      {
        key: 6,
        text: "Optimizar la atención al cliente",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "Beneficios de la automatización para un E-commerce",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Importancia de la automatización con chatbots ",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "¿Por qué automatizar un e-commerce con WhatsApp? ",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
